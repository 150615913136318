<template>
  <router-link
    to="./product"
    class="
      text-base
      p-2
      bg-black bg-opacity-50
      rounded-2xl
      inline-block
      no-underline
      border-b border-gray-100
      text-gray-100
      leading-relaxed
      hover:text-yellow-600 hover:border-yellow-600
    "
  >
    Daha fazla...
  </router-link>
</template>

<script>
export default {
  name: "MoreButton",
  components: {},
};
</script>

<style></style>
