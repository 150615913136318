<template>
  <header>
    <nav
      id="header"
      class="
        w-full
        z-30
        top-0
        py-1
        border-b-2 border-yellow-700
        dark:border-yellow-600 dark:bg-gray-100
        bg-gray-900
      "
    >
      <div
        class="
          w-full
          container
          mx-auto
          flex flex-wrap
          items-center
          justify-between
          mt-0
          px-6
          py-3
        "
      >
        <label for="menu-toggle" class="cursor-pointer md:hidden block">
          <svg
            class="fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
        <input class="hidden" type="checkbox" id="menu-toggle" />
        <div
          class="
            hidden
            md:flex md:items-center md:w-auto
            w-full
            order-3
            md:order-1
          "
          id="menu"
        >
          <nav>
            <ul
              class="
                md:flex
                items-center
                justify-between
                text-base
                font-semibold
                text-gray-700
                pt-4
                md:pt-0
              "
            >
              <li>
                <router-link
                  to="/"
                  class="
                    inline-block
                    no-underline
                    text-yellow-600
                    hover:text-yellow-400
                    py-2
                    px-4
                  "
                  >Anasayfa</router-link
                >
              </li>
              <li>
                <router-link
                  to="/product"
                  class="
                    inline-block
                    no-underline
                    text-yellow-600
                    hover:text-yellow-400
                    py-2
                    px-4
                  "
                  >Ürünler</router-link
                >
              </li>
              <li>
                <router-link
                  to="/about"
                  class="
                    inline-block
                    no-underline
                    text-yellow-600
                    hover:text-yellow-400
                    py-2
                    px-4
                  "
                  >Kurumsal</router-link
                >
              </li>
              <li>
                <router-link
                  to="/contact"
                  class="
                    inline-block
                    no-underline
                    text-yellow-600
                    hover:text-yellow-400
                    py-2
                    px-4
                  "
                  >İletişim</router-link
                >
              </li>
              <li>
                <a
                  class="
                    inline-block
                    no-underline
                    text-yellow-600
                    hover:text-yellow-400
                    py-2
                    px-4
                  "
                  target="_blank"
                  href="https://drive.google.com/file/d/1LLLFLC_h8XOrHXprihLXFbmLgV5Ps3z1/view?usp=sharing"
                  download
                  >Katalog</a
                >
              </li>

              <li class="mt-3 ml-4">
                <Toggle />
              </li>
            </ul>
          </nav>
        </div>
        <div class="">
          <router-link
            to="/"
            class="
              flex
              items-center
              tracking-wide
              no-underline
              hover:no-underline
              font-bold
              text-gray-800 text-xl
            "
            ><img class="w-20 sm:w-20" src="../../public/img/mgsLogoYenii.png"
          /></router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import Toggle from "@/components/share/Toggle";
export default {
  name: "MainHeader",
  components: {
    Toggle,
  },
};
</script>

<style scoped>
.work-sans {
  font-family: "Work Sans", sans-serif;
}

#menu-toggle:checked + #menu {
  display: block;
}

.hover\:grow {
  transition: all 0.3s;
  transform: scale(1);
}

.hover\:grow:hover {
  transform: scale(1.02);
}
</style>
>
