<template>
  <div class="home bg-gray-800 dark:bg-gray-100">
    <Carousel />
    <div
      class="border-t-2 w-full border-yellow-700 dark:border-yellow-600"
    ></div>
    <div
      class="
        w-full
        container
        pb-14
        bg-gray-800
        dark:bg-gray-100
        mx-auto
        p-4
        relative
        py-26
        lg:mt-10
        
      "
    > 
      <div class="relative max-w-screen-xl">
        <a
          class="
            tracking-wide
            no-underline
            hover:no-underline
            font-bold
            text-yellow-700
            dark:text-yellow-600
            text-2xl
            mb-8
          "
          href="#"
        >
          Ürünlerimiz
        </a>
        <div>
          <div
            class="
              w-full
              flex
              p-2
              lg:p-0 lg:space-x-12
              flex-col
              lg:flex-row
              space-y-5
              sm:space-y-10
            "
          >
            <div
              class="
                lg:w-4/12
                flex
                items-center
                justify-center
                flex-col-reverse
              "
            >
              <router-link class="pt-4" to="/product"
                ><LeftMenuItem1
              /></router-link>
              <img
                class="sm:h-40 mt-9 sm:w-60 w-72 h-56"
                src="../../../public/img/mutfak1.jpg"
                alt="Flowers"
              />
            </div>
            <div
              class="
                lg:w-4/12
                flex
                items-center
                justify-center
                flex-col-reverse
              "
            >
              <router-link class="pt-4" to="/product"
                ><LeftMenuItem2
              /></router-link>
              <img
                class="sm:h-40 sm:w-60 w-72 h-56"
                src="../../../public/img/giyinme2.jpg"
                alt="Flowers"
              />
            </div>
            <div
              class="
                lg:w-4/12
                flex
                items-center
                justify-center
                flex-col-reverse
              "
            >
              <router-link class="pt-4" to="/product"
                ><LeftMenuItem4
              /></router-link>
              <img
                class="sm:h-40 sm:mt-5 sm:w-60 w-72 h-56"
                src="../../../public/img/v2.jpg"
                alt="Flowers"
              />
            </div>
            <div
              class="
                lg:w-4/12
                flex
                items-center
                justify-center
                flex-col-reverse
              "
            >
              <router-link class="pt-4" to="/product"
                ><LeftMenuItem5
              /></router-link>
              <img
                class="sm:h-40 sm:w-60 w-72 h-56"
                src="../../../public/img/tv3.jpg"
                alt="Flowers"
              />
            </div>
            <div
              class="
                lg:w-4/12
                flex
                items-center
                justify-center
                flex-col-reverse
              "
            >
              <router-link class="pt-4" to="/product"
                ><LeftMenuItem6
              /></router-link>
              <img
                class="sm:h-40 sm:w-60 w-72 h-56"
                src="../../../public/img/banyo1.jpg"
                alt="Flowers"
              />
            </div>
            <div
              class="
                lg:w-4/12
                flex
                items-center
                justify-center
                flex-col-reverse
              "
            >
              <router-link class="pt-4" to="/product"
                ><LeftMenuItem7
              /></router-link>
              <img
                class="sm:h-40 sm:w-60 w-72 h-56"
                src="../../../public/img/ray8.jpg"
                alt="Flowers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/share/Carousel";
import LeftMenuItem1 from "@/components/share/LeftMenuItems/LeftMenuItem1";
import LeftMenuItem2 from "@/components/share/LeftMenuItems/LeftMenuItem2";
import LeftMenuItem4 from "@/components/share/LeftMenuItems/LeftMenuItem4";
import LeftMenuItem5 from "@/components/share/LeftMenuItems/LeftMenuItem5";
import LeftMenuItem6 from "@/components/share/LeftMenuItems/LeftMenuItem6";
import LeftMenuItem7 from "@/components/share/LeftMenuItems/LeftMenuItem7";
//import FAQs from "@/components/FAQs/";

export default {
  name: "Home",
  components: {
    Carousel,
    //FAQs,
    LeftMenuItem1,
    LeftMenuItem2,
    LeftMenuItem4,
    LeftMenuItem5,
    LeftMenuItem6,
    LeftMenuItem7,
  },
};
</script>

<style>
.urunler {
  width: 270px;
  height: 270px;
}
</style>
