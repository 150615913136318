<template>
  <div class="footer">
    <footer
      class="
        bg-gray-900
        dark:bg-gray-100
        h-full
        mx-auto
        border-t-2 border-yellow-700
        dark:border-yellow-600
      "
      :class="[
        $route.name == 'About'
          ? 'bg-gray-900 h-full mx-auto border-t-2 border-yellow-700 dark:border-yellow-600'
          : '',
      ]"
    >
      <div class="flex px-3 pt-6 pb-8">
        <div class="w-full mx-auto flex flex-wrap">
          <div class="w-full pt-8 lg:-ml-0 lg:pl-4 lg:w-4/12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12517.487062387276!2d38.2339893!3d38.3403766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7732808e8d5849f!2smgs%20mobilya!5e0!3m2!1str!2str!4v1638728195792!5m2!1str!2str" height="350" class="sm:w-96 w-auto mx-auto" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
          <div class="flex w-full lg:-ml-8 lg:mr-4 lg:w-4/12">
            <div class="px-3 md:px-0">
              <div class="flex items-center justify-center sm:py-0  py-4 pb-5">
<img class="w-8/12" src="../../public/img/mgsLogoYenii.png" alt="logo">
<!-- <img class="dark:block hidden" src="../../public/img/M5.svg" alt="logo"> -->
<!-- <img class="dark:hidden block" src="../../public/img/M6.svg" alt="logo"> -->
</div>
              <h3 class="text-center text-yellow-600 mt-5 pb-4 hidden md:block">
                Sosyal Medya
              </h3>
              <div class="flex -ml-8 lg:-ml-0 justify-center items-center">
              <SMButtons />
              </div>
            </div>
          
          </div>
          <div class="flex w-full lg:w-4/12">
            <div class="px-3 lg:pt-24 md:px-0">
              <h3 class="font-bold text-gray-900 invisible">About</h3>
              <div class="pt-1 text-yellow-600">
                Adres:
                <p class="text-gray-200 dark:text-gray-800">
                  Özsan Sanayi Sitesi 21.Blok No:11 Yeşilyurt/Malatya                </p>
              </div>
              <div class="pt-2 text-yellow-600">
                Telefon:
                <p class="text-gray-200 dark:text-gray-800">0533 966 9003</p>
              </div>
              <div class="text-yellow-600 pt-2">
                Mail:
                <p class="text-gray-200 dark:text-gray-800">
                  mgsmobilya.tr@gmail.com
                </p>
              </div>
                          <div
              class="
                px-3
                py-7
                md:mr-10 md:px-0
                flex
                items-center
                mx-auto
                md:mx-0 md:inline
              "
            >
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center pt-4 items-center">
        <p class="flex text-gray-600 text-sm mb-1">
          Designed and built with by&nbsp;<a
            rel="license"
            class="text-pink-700"
            href="https://www.linkedin.com/in/selahattin-y%C4%B1ld%C4%B1r%C4%B1m-b49973169/"
            >selahattinYildirim</a
          >
        </p>
      </div>
      <div class="text-gray-600 text-sm flex justify-end items-center mr-2">
        This work is licensed under&nbsp;
        <a
          rel="license"
          class="text-indigo-500"
          href="http://creativecommons.org/licenses/by-nc-nd/4.0/"
        >
          CC BY-NC-ND 4.0
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import SMButtons from "@/components/share/SocialMediaButtons";
export default {
  name: "MainFooter",
  components: {
    SMButtons,
  },
};
</script>
