<template>
  <p
    class="
      block
      pb-8
      p-2
      font-base
      text-gray-300
      dark:text-gray-800
      hover:underline
    "
  >
    Giyim Odaları
  </p>
</template>

<script>
export default {
  name: "LeftMenuItem1",
  components: {},
};
</script>

<style></style>
