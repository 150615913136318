<template>
  <ul
    class="
      list-reset
      flex
      space-x-10
      bg-black
      p-2
      rounded-2xl
      bg-opacity-20
      ml-8
      sm:ml-0
      items-center
      pt-3
    "
  >
    <li>
      <a class="inline-block no-underline py-1"  target="_blank"  href="https://instagram.com/mgsmobilya?utm_medium=copy_link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-200 hover:text-yellow-600"
          :class="[
            $route.name == 'TopSection'
              ? 'text-red-600 hover:text-gray-900'
              : '',
          ]"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentcolor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="16" rx="4" />
          <circle cx="12" cy="12" r="3" />
          <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
        </svg>
      </a>
    </li>
    <li>
      <a class="inline-block no-underline py-1" target="_blank"  href="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-200 hover:text-yellow-600"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentcolor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
          <path
            d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1"
          />
        </svg>
      </a>
    </li>
    <li>
      <a class="inline-block no-underline py-1" target="_blank"  href="https://business.facebook.com/latest/home?asset_id=116641593500337&nav_ref=pages_classic_isolated_section_inbox_redirect">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-200 hover:text-yellow-600"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentcolor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
          />
        </svg>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SMButtons",
};
</script>

<style></style>
